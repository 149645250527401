var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{staticClass:"avatars",attrs:{"tile":""}},[_c('img',{attrs:{"src":_vm.publication.url,"alt":_vm.publication.paper_id}})]),_c('v-list-item-content',[_c('div',{staticClass:"pub-authors"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.publication.authors)}}),_c('span',{staticClass:"pub-year",domProps:{"innerHTML":_vm._s(_vm.publication.year)}})]),_c('div',{staticClass:"pub-title",domProps:{"innerHTML":_vm._s(_vm.publication.title)}}),_c('div',{staticClass:"pub-venue",domProps:{"innerHTML":_vm._s(_vm.publication.venue)}}),_c('div',{staticClass:"pub-icons"},[_c('v-row',{staticClass:"ml-1 mt-0",attrs:{"align":"center","justify":"start"}},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard(
                    _vm.publication.copy_citation,
                    'The citation is copied to your clipboard.'
                  )}}},'v-icon',attrs,false),on),[_vm._v("fas fa-copy ")])]}}])},[_c('span',[_vm._v("copy citation")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-1 iconfromsvg",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.copyToClipboard(
                    _vm.publication.bibtex,
                    'The bibtex code is copied to your clipboard.'
                  )}}},[_c('img',_vm._g(_vm._b({attrs:{"src":require("@/assets/icons/nounproject_TEX File_342079.svg"),"alt":"BibTex"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("copy bibtex")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-2",attrs:{"href":_vm.publication.paper_pdf,"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-file-pdf")])],1)]}}])},[_c('span',[_vm._v("download PDF")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-2",attrs:{"href":_vm.publication.short_preview_video,"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-film")])],1)]}}])},[_c('span',[_vm._v("video preview")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-2",attrs:{"href":_vm.publication.suppementary_material,"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v("fas fa-cog")])],1)]}}])},[_c('span',[_vm._v("supplementary material")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-2",attrs:{"href":_vm.publication.presentation_slides,"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v("mdi-presentation")])],1)]}}])},[_c('span',[_vm._v("presenation slides")])]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pub-icon-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-1 iconfromsvg",attrs:{"href":_vm.publication.presentation_video,"target":"_blank"}},[_c('img',_vm._g(_vm._b({attrs:{"src":require("@/assets/icons/nounproject_Lecturer_8076.svg"),"alt":"Lecturer"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("live presentation")])])],1)],1)])],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"top":"","right":"","text":"","color":"gray","content-class":"action-notification"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"gray","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }